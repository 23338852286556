import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/app',
        name: 'app',
        component: App,
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: 'orderlist',
                name: 'orderlist',
                component: () => import('./components/order/OrderListBlock.vue'),
            },
            {
                path: 'createfbafulfillmentorder',
                name: 'createfbafulfillmentorder',
                component: () => import('./components/order/CreateFbaFulfillmentOrder.vue'),
            },
            {
                path: 'inventorylist',
                name: 'inventorylist',
                component: () => import('./components/inventory/InventoryListBlock.vue'),
            },
            {
                path: 'asindata',
                name: 'setasindata',
                component: () => import('./components/inventory/SetAsinData.vue')
            },
            {
                path: 'shipmentlist',
                name: 'shipmentlist',
                component: () => import('./components/shipment/ShipmentListBlock.vue')
            },
            {
                path: 'createshipment',
                name: 'createshipment',
                component: () => import('./components/shipment/CreateShipment.vue')
            },
            {
                path: 'shipmenttool',
                name: 'shipmenttool',
                component: () => import('./components/shipment/ShipmentTool.vue')
            },
            {
                path: 'viewcode',
                name: 'viewcode',
                component: () => import('./components/ViewCode.vue')
            },
            {
                path: 'message',
                name: 'message',
                component: () => import('./components/MessageBlock.vue')
            },
            {
                path: 'storemanage',
                name: 'storemanage',
                component: () => import('./components/StoreManageBlock.vue')
            },
            {
                path: 'subaccount',
                name: 'subaccount',
                component: () => import('./components/SubAccountBlock.vue')
            },
            {
                path: 'editimage',
                name: 'editimage',
                component: () => import('./components/EditImage.vue')
            },
            {
                path: 'openai',
                name: 'openai',
                component: () => import('./components/openai.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/',
        name: 'welcome',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to) => {
    // 检查是否登录
    if(localStorage.getItem("token")==null&&to.name !== 'login'){
        //return { name: 'login' }
   }
  })

export default router;
