<template>
	<div :class="containerClass" >
        <AppTopBar :user="user"   @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" :isRoot="true" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <router-view  />
            </div>
        </div>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import Service from './service/Service';
export default {
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            service:null,
            user:null,
            menu : [
                {
                    label: '首页',
                    items: [{
                        label: '数据看板', icon: 'pi pi-fw pi-home', to: '/app/dashboard',
                        command: () => {
                             //object.originalEvent.preventDefault();
                            // window.location.hash='/access';
                        }
                    }]
                },
				{
					label: '订单',
					items: [
						{label: '订单列表', icon: 'pi pi-shopping-cart', to: '/app/orderlist'},
                        {label: '创建订单', icon: 'pi pi-plus-circle', to: '/app/createfbafulfillmentorder'},
					]
				},
                {
                    label: '库存',
                    items: [
                        {label: '库存列表', icon: 'pi pi-database', to: '/app/inventorylist'},
                        {label: 'ASIN管理', icon: 'pi pi-cog', to:'/app/asindata'},
                    ]
                },
                {
                    label: '货件',
                    items: [
                        {label: '货件管理', icon: 'pi pi-truck', to: '/app/shipmentlist'},
                        {label: '创建货件', icon: 'pi pi-plus-circle', to: '/app/createshipment'},
                        {label: '货件助手', icon: 'pi pi-wrench', to: '/app/shipmenttool'}
                    ]
                },
				{
					label: '广告',
					items: [
                        {label: '广告管理', icon: 'pi pi-fw pi-amazon', to: '/advertising/campaign/campaigns',target:'_blank'},
					]
				},
                {
                    label: '系统', 
                    items: [
                        {label: '查看验证码', icon: 'pi pi-eye', to:'/app/viewcode'},
                        {label: '查看短信', icon: 'pi pi-comment', to:'/app/message'},
                        {label: '店铺管理', icon: 'pi pi-th-large', to:'/app/storemanage'},
                        {label: '子账号管理', icon: 'pi pi-user-edit', to:'/app/subaccount'},
                        {label: '抠图工具', icon: 'pi pi-images', to:'/app/editimage'},
                        //{label: 'ChatGPT', icon: 'pi pi-comment', to:'/app/openai'},
                    ]
                },
            ]
        }
    },
    setup(){
        
    },
    created() {
        this.service = new Service();
        if(!localStorage.getItem('user')){
            var url='getuser.php';
            this.service.sendHttp(url).then(res=>res.json()).then(data=>{
                if(data.result=='success'){
                    this.user=data.user;
                    localStorage.setItem("user",JSON.stringify(this.user));
                }
                else{
                    this.$router.push({path:"/login"});
                }
            });
        }else{
            this.user=JSON.parse(localStorage.getItem('user'));
        }
        this.getdaylight();
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
   
    methods: {
        getStores(){
            if(!localStorage.getItem('stores')){
                var url='system/getstorelist.php';
                const formData = new FormData();
                formData.append('userid', this.user.UserId);
                formData.append('owner', this.user.Owner);
                this.service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data=>{
                    if(data.result=='notlogin'){
                        this.$router.push({path:"/login"});
                        return;
                    }
                    localStorage.setItem("stores",JSON.stringify(data.stores));
            });
        }
        },
        getUser(){
            
        },
        getdaylight(){
			var date=new Date();
            var year=date.getFullYear();
            date=new Date(year,2,1);
            var day=date.getDay()==0?8:(15-date.getDay());
            date=new Date(year,2,day,18,0,0);
            localStorage.setItem("dateStart",date.getTime());
            date=new Date(year,10,1);
            day=date.getDay()==0?1:(8-date.getDay());
            date=new Date(year,10,day,15,0,0);
            localStorage.setItem("dateEnd",date.getTime());
		},
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }
            return true;
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        }
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
