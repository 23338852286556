<template>
    <router-view></router-view>
</template>

<script>

export default {
    created() {
        for (var i = 0; i < sessionStorage.length; i++) {
            var key = sessionStorage.key(i);
            localStorage.setItem(key,sessionStorage.getItem(key));
        }
    },
    mounted(){
        if(localStorage.getItem('keepLogin')!='true'){
            if(!localStorage.getItem('pageCount')){
                localStorage.setItem('pageCount','1');
            }
            else{
                var pageCount=parseInt(localStorage.getItem('pageCount'));
                pageCount++;
                localStorage.setItem('pageCount',pageCount);
            }
        }
        window.onbeforeunload = function () {
            if(localStorage.getItem('keepLogin')!='true'){
                var pageCount=parseInt(localStorage.getItem('pageCount'));
                pageCount--;
                if(pageCount<=0){
                    for (var i = 0; i < localStorage.length; i++) {
                        var key = localStorage.key(i);
                        sessionStorage.setItem(key,localStorage.getItem(key));
                    }
                    localStorage.clear();
            }
        }
        }
        
    },
    unmounted(){
    },
    beforeUnmount() {
    }
}
</script>