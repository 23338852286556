
<template>
	<div class="layout-topbar">
		<button class="p-link layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<router-link to="/app/dashboard" class="layout-topbar-logo">
			<span>MZSpace</span>
		</router-link>
		

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<label style="margin: 0 auto;color:#ffffff;font-size: large;">美国时间：{{time}}</label>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<Button type="button" class="p-button-lg" icon="pi pi-user" :label="user==null?'':user.UserId" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu"/>
				<Menu id="overlay_menu" style="width:50px" ref="menu" :model="items" :popup="true" />
			</li>
		</ul>
	</div>
</template>

<script>
import router from './router';
export default {
	data(){
		return{
			time:null,
			daylight:{
				dateStart:null,
				dateEnd:null
			},
			items: [
                    {
                        label: '退出',
                        icon: 'pi pi-sign-out',
						command: () => {
							router.push({path:"/login"});
                        }
                    }
            ]
		}
	},
	props: {
		user: Object,
  },
	mounted(){
		this.daylight.dateStart=localStorage.getItem("dateStart");
		this.daylight.dateEnd=localStorage.getItem("dateEnd");
		setInterval(this.getTime,1000);
	},
    methods: {
		toggle(event) {
            this.$refs.menu.toggle(event);
        },
		getTime(){
            var date=new Date();
			if(this.daylight.dateStart<=date.getTime()&&date.getTime()<this.daylight.dateEnd){
                date.setTime(date.valueOf()-15*60*60*1000);
			}
			else{
				date.setTime(date.valueOf()-16*60*60*1000);
			}
            this.time=date.toLocaleString();
		},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>
<style>
.layout-topbar .layout-topbar-logo:focus{
	box-shadow:none;
}
</style>